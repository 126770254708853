.status.error{
    color: red;
}
.status.running{
    color: green;
}

.status.stopped, .status.loading{
    color: orange;
}
