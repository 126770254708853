@import url("./theme.css");

.file-download{
    font-size: 0.7rem;
     padding: 7px;
}

.misc{
    margin-left: auto;
}
.misc button{
    font-size: 0.7rem;
    padding: 10px;
    margin: 0;
}
.misc .row{
    margin: 5px 0;
    align-items: stretch;
}
.misc select{
    min-width:100px;
}

select{
    margin:0;
}

button {
    margin: 3px;
    padding: 6px;
    padding-right: 15px;
    padding-left: 15px;
}

.row {
    display: flex;
    align-items: center;
}

html {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

body {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    overflow: hidden;
    max-width: unset;
    margin: 0;
    width: 100%;
}

@media (min-width: 1200px),
screen and (orientation: landscape) {
    body {
        width: 80%;
    }
}

body>section:first-child {
    border-top: none;
}

body>section {
    border-top: 1px solid white;
    padding: 5px;
    overflow: auto;
}

#view {
    flex: 2;
    padding: 0;
}

.logs {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

pre {
    margin: 0;
}

.probe-spec {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

section,
.probe-spec {
    flex-wrap: wrap;
}

.clickable {
    cursor: pointer;
}

.transforms select {
    font-size: 1rem;
}

.probe-stats {
    font-size: 0.8em;
    text-align: right;
    align-self: flex-start;
}


.preset{
    margin-left: auto;;
}
.transform{
    margin: 0 5px;
}

.debug{
    margin-left: auto;
    font-size: 0.6rem;
    display: flex;
    align-items: baseline;
}

.debug label{
    margin:0 !important;
    padding:0;
}
.debug > span {
    margin: 0 10px;
    display:flex;
    align-items:center;
}
.debug input[type="checkbox"]{
    margin-top:0;
    margin-bottom:0;
}

#logs{
    display: none;
}

